import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="About" />
      <h1>About</h1>
      <p>I'm Andrew, a software developer from Glasgow, Scotland.</p>
      <p>I'm currently working towards a BSc in Software Engineering (Graduate Apprenticeship) at the University of Glasgow - with a split between study and work based learning.</p>
      <p>Outside of tech I'm interested in music, personal finance/investing and minimalism.</p>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
